  .slide-left-panel {
    visibility: hidden;
  }

  .cd-panel-header-close {
    width: 220px;
    height: 100%;
    border-right-style: solid;
    border-right-color: transparent; 
    border-right-width: 1px;
    
    visibility: initial;
    position: fixed;
    top: 70;
    transition: transform 0.3s 0s;
    transform: translateX(-100%);
  }

  
  .cd-panel-header-open {
    width: 300px;
    height: 100%;
    border-right-style: solid;
    border-right-color: transparent; 
    border-right-width: 1px;
    z-index: 999999;
    visibility: initial;
    position: fixed;
    top: 70;
    transition: transform 0.3s 0s;
    transform: translateX(0px);
  }  
  
  .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded::before {
    background-color: transparent;
  }
