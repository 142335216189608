#login-canvas
{
  width:  100%;
  height:  100%;
  text-align: center;
  text-align: -webkit-center;
}

#top-spinner{
  position: absolute;
  display: block;
  margin-left: auto;
  margin-top: 50%;
  width:  100%;
  height:  100%;
}

#spin{
	z-index: 99;

}

.wait{
   position: relative;		
   top: 50%;	
}

.nowait{
  display: flex;
}

#username-helper-text{
  color:  red;
}