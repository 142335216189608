html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
}

body:after {
  background: url(../images/beach.jpg) no-repeat scroll;
      content:"";
      position: fixed; /* stretch a fixed position to the whole screen */
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      z-index:-1; /* needed to keep in the background */

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;  
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MainApp {
  width: 100%;
  height: 100%;
}

table {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  margin: 5vh;
}

td {
  border: 1px solid gray;
  width: 30em;
}

.Budget-Overview {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Table-Header {
  background-color: #96bb7c;
}
#seperator {
  margin-top: 20px;
}

.Toastify {
  position: absolute;
  margin-top: 70px !important;
}

.drinkaware.hazel{
  width: 10%;
}

.drinkaware{
  position: fixed;
  bottom: 0;
  left: 0;  
}

.sub-canvas {
  /* display: -webkit-inline-box; */
}

