.block {
    box-sizing: content-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-align-items: stretch;
            align-items: stretch;
  }
  
  .block.repel {
    margin-left: auto;
  }
  
  .block.vertical {
    flex-direction: column;
  }
  
  .block.cell.primary{
      -webkit-flex: 1 1 0%;
              flex: 1 1 0%;
      overflow: hidden;
  }
  
  
  /* Repel class makes a cell push itself and following cells to the opposite end of the block */
  .block--vertical.repel {
    margin-top: auto;
    margin-left: 0;
  }
  
  .block.row{
    margin-left: 5px;
    margin-bottom: 15px;
  }

.xmodal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 10px; /* Location of the box */
    left: 0px;
    top: 0px;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); 
}


.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: red;
}

.modal-content {
    position: absolute;
    bottom: auto;
    /* margin-top: 30%; */
    /* left: calc(-15%); */
    height: 370px;
    width: 600px;
    z-index: 9999999;
  }
  
  .modal-body{
   height: 250px; 
   color: black;
  }

  
  .fade.in > div > div {
    height: 600px;
  }
  
  .fade.in > div > div > div > .modal-body{
    height: 500px;
  }
  
  .modal-header{
    min-height: 16.43px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background-color: white;
  }
    
  .modal-header.close{
    margin-top: -2px;
  }
  
  button.close {
    /* -webkit-appearance: none; */
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
  }

  .modal-body{
    height: 70px; 
    font-size: 14px;
  }

  div.modal-body{
    padding: 10px;
    display: block;
    background-color: lightgray;  
    width: 100%;
  }
  
  .modal-body.gdpr-bg{
    height: 250px; 
    color: black;
    background-color: #ffffff;
   }

  .modal {
    font-size: 12px;
  }
  .modal.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
 
  .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  
  .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
  }

  .modal-title{
    color: black;
  }
  
  
#gdpr{
    position: fixed;
    bottom: 0;
    left: 0;
    
    background-color: #F92C8B;  
    border-color: #F92C8B;
    border-style: solid;  
    border-width: 1px;
    width: 100%;  
    text-align: -moz-center;  
    text-align: center;
}

#gdpr-sub{
    margin-top:  24px;
    display: inline-flex;
    z-index: 9999999;
}

#gdpr-text{
    margin-left:  10px;
    font-size: 18px;
    font-style: italic; 
    margin-bottom: 15px;
}

#gdpr-settings{
    margin-left:  10px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
}

#gdpr-btn-accept{
    text-align: -webkit-center;
    text-align: -moz-center; 
    line-height: 26px; 
    margin-left:  20px;
    margin-right: 10px;  
    margin-bottom: 10px; 
    padding-left: 10px;
    padding-right: 10px;   
    border-color: #B02CD6;
    border-style: solid;  
    border-width: 1px;  
    border-radius: 9px;
    height: 30px;
    width: 90px;
    background-color: #B02CD6;
    font-size: 18px;
    cursor: pointer;
}

.gdpr-App {
    display: -webkit-inline-box;
}