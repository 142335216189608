#stagenow-dlg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #173A57;
	text-align: center;
/*
	border-color: black;
	border-style: solid;
	border-width: 2px;
*/	z-index: 99999;
}

#stagenow_title
{
	text-align: center;
	padding: 5px;
	width: 100%;
	height: 25%;
	font-size: 24px;
	font-weight: bold;
}

#stagenow_title span
{
	color: #FF3523;
}

#stagenow_options
{
}

.upgrade-cancel span{
	font-size: 18px;
	font-weight: bold;
	color: white;
}

#stagenow_new{
	margin-bottom: 100px;
}

.title{
	color: lightgreen;
	margin-right: 10px;
}

.title-text{
	color: yellow;
}

.app-title span{
	color: white;
	font-size: 24px;
	font-weight: bold;
}